import { template as template_cd8235dae22d45d4a2953bb4bc589e54 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_cd8235dae22d45d4a2953bb4bc589e54(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
