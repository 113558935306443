import { template as template_a0fab11e5e514c37a906a83e2bfacb68 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
const CategoryTitleBefore = template_a0fab11e5e514c37a906a83e2bfacb68(`
  <PluginOutlet
    @name="category-title-before"
    @outletArgs={{hash category=@category}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryTitleBefore;
