import { template as template_256f894067cf41119f4add203b3dbb7e } from "@ember/template-compiler";
const FKLabel = template_256f894067cf41119f4add203b3dbb7e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
