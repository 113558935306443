import { template as template_99e48b0bbc8f4d9e88f7a6afbc0aa507 } from "@ember/template-compiler";
const FKControlMenuContainer = template_99e48b0bbc8f4d9e88f7a6afbc0aa507(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
